.hello {
  display: "flex" !important;
  justify-content: "center" !important;
  width: 100%;
  background: rebeccapurple;
}

.SucessContainer .w3-modal-icon {
  border-radius: 50%;
  border: 4px solid gray;
  box-sizing: content-box;
  height: 80px;
  padding: 0;
  position: relative;
  width: 80px;
  display: flex;
  justify-content: center;
}
.SucessContainer .w3-modal-icon.w3-modal-success,
.SucessContainer .w3-modal-icon.f-modal-error {
  border-color: #a5dc86;
}
.SucessContainer .w3-modal-icon.w3-modal-success:after,
.SucessContainer .w3-modal-icon.w3-modal-success:before,
.SucessContainer .w3-modal-icon.f-modal-error:after,
.SucessContainer .w3-modal-icon.f-modal-error:before {
  /* background: #fff; */
  content: "";
  height: 120px;
  position: absolute;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 60px;
}
.SucessContainer .w3-modal-icon.w3-modal-success:before,
.SucessContainer .w3-modal-icon.f-modal-error:before {
  border-radius: 120px 0 0 120px;
  left: -33px;
  top: -7px;
  -webkit-transform-origin: 60px 60px;
  transform-origin: 60px 60px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.SucessContainer .w3-modal-icon.w3-modal-success:after,
.SucessContainer .w3-modal-icon.f-modal-error:after {
  border-radius: 0 120px 120px 0;
  left: 30px;
  top: -11px;
  -webkit-transform-origin: 0 60px;
  transform-origin: 0 60px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.SucessContainer .w3-modal-icon.w3-modal-success .w3-modal-placeholder,
.SucessContainer .w3-modal-icon.f-modal-error .w3-modal-placeholder {
  border-radius: 50%;
  border: 4px solid rgba(165, 220, 134, 0.2);
  box-sizing: content-box;
  height: 80px;
  left: -4px;
  position: absolute;
  top: -4px;
  width: 80px;
  z-index: 2;
}
.SucessContainer .w3-modal-icon.w3-modal-success .w3-modal-fix,
.SucessContainer .w3-modal-icon.f-modal-error .w3-modal-fix {
  /* background-color: #fff; */
  height: 90px;
  left: 28px;
  position: absolute;
  top: 8px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 5px;
  z-index: 1;
}
.SucessContainer .w3-modal-icon.w3-modal-success .w3-modal-line,
.SucessContainer .w3-modal-icon.f-modal-error .w3-modal-line {
  background-color: #a5dc86;
  border-radius: 2px;
  display: block;
  height: 5px;
  position: absolute;
  z-index: 2;
}
.SucessContainer .w3-modal-icon.w3-modal-success .w3-modal-line.w3-modal-tip,
.SucessContainer .w3-modal-icon.f-modal-error .w3-modal-line.w3-modal-tip {
  left: 14px;
  top: 46px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 25px;
}
.SucessContainer .w3-modal-icon.w3-modal-success .w3-modal-line.w3-modal-long,
.SucessContainer .w3-modal-icon.f-modal-error .w3-modal-line.w3-modal-long {
  right: 8px;
  top: 38px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  width: 47px;
}

.SucessContainer .w3-modal-icon + .w3-modal-icon {
  margin-top: 50px;
}

.animateSuccessTip {
  -webkit-animation: animateSuccessTip 0.75s;
  animation: animateSuccessTip 0.75s;
}

.animateSuccessLong {
  -webkit-animation: animateSuccessLong 0.75s;
  animation: animateSuccessLong 0.75s;
}

.w3-modal-icon.w3-modal-success.animate:after {
  -webkit-animation: rotatePlaceholder 4.25s ease-in;
  animation: rotatePlaceholder 4.25s ease-in;
}

@-webkit-keyframes animateSuccessTip {
  0%,
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes animateSuccessTip {
  0%,
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes animateSuccessLong {
  0%,
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}
@keyframes rotatePlaceholder {
  0%,
  5% {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  100%,
  12% {
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg);
  }
}
